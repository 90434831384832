import React from "react"
import Grid from "@material-ui/core/Grid"
import Section from "components/section"

import style from "./index.module.scss"

const PreFooter = () => (
  <Section>
    <Grid container spacing={0}>
      <Grid item xs={12} md={6}>
        <p className={style.headingGrey}>Sie haben Fragen?</p>
        <h2 className={style.heading}>Kontaktieren Sie mich gerne!</h2>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className={style.box}>
          <div>
            <p className={style.label}>E-Mail:</p>
            <a className={style.link} href="mailto:praxis@psychotherapie-schett.at">praxis@psychotherapie-schett.at</a>
          </div>
          <span className={style.seperator}></span>
          <div>
            <p className={style.label}>Telefon:</p>
            <a className={style.link} href="tel:004367762760453">+43 677 62760453</a>
          </div>
        </div>
      </Grid>
    </Grid>
  </Section>
)

export default PreFooter
