import React from "react"
import Grid from "@material-ui/core/Grid"
import Section from "components/section"
import Svg from "components/svg"
import MenuItem from "components/menu-item"

import logo from "images/logo.svg"
import style from "./index.module.scss"

const Footer = () => (

  <footer className={style.footer}>
    <Section hasPadding hasMargin={false}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={5} className={style.gridItem1}>
          <Svg
            className={style.brand}
            src={logo}
            alt="Hermine Schett"
          />
          <p className={style.title}>
            Psychotherapeutin in<br/>Ausbildung unter Supervision
          </p>
        </Grid>

        <Grid item xs={12} sm={3} md={2} className={style.gridItem}>
          <p className={style.menuHeading}>
            Mitgliedschaften
          </p>
          <ul className={style.menu}>
            <MenuItem
              to="https://www.psychotherapie.at/patientinnen"
              text="ÖBVP"
              className={style.menuItem}
              linkClassName={style.menuLink}
              isExternal
            />
            <MenuItem
              to="https://oegatap.at/"
              text="ÖGATAP"
              className={style.menuItem}
              linkClassName={style.menuLink}
              isExternal
            />
          </ul>
        </Grid>

        <Grid item xs={12} sm={3} md={2} className={style.gridItem}>
            <p className={style.menuHeading}>
              Rechtliches
            </p>
          <ul className={style.menu}>
            <MenuItem
              to="/impressum/"
              text="Impressum"
              className={style.menuItem}
              linkClassName={style.menuLink}
              hasActiveClass={false}
            />
            <MenuItem
              to="/datenschutz/"
              text="Datenschutz"
              className={style.menuItem}
              linkClassName={style.menuLink}
              hasActiveClass={false}
            />
          </ul>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={style.gridItem}>
          <p className={style.menuHeading}>
            Kontakt
          </p>
          <ul className={style.menu}>
            <MenuItem
              to="mailto:praxis@psychotherapie-schett.at"
              text="praxis@psychotherapie-schett.at"
              className={style.menuItem}
              linkClassName={style.menuLink}
              isExternal
            />
            <MenuItem
              to="tel:004367762760453"
              text="+43 677 62760453"
              className={style.menuItem}
              linkClassName={style.menuLink}
              isExternal
            />
          </ul>
        </Grid>
      </Grid>
    </Section>

  </footer>
)

export default Footer
