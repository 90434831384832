import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import classNames from "classnames"

import style from "./index.module.scss"

const MenuItem = ({ to, text, isExternal, hasActiveClass, className, linkClassName}) => (
  <li className={classNames([style.menuItem], className)}>
    {!isExternal && (
      <Link
        to={to}
        className={classNames([style.menuLink],[style.typeBold],linkClassName)}
        activeClassName={classNames({[style.menuLinkActive]: hasActiveClass})}
      >
        {text}
      </Link>
    )}
    {isExternal && (
      <a
        href={to}
        target="blank"
        className={classNames([style.menuLink],[style.typeBold],linkClassName)}
      >
        {text}
      </a>
    )}
  </li>
)

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
  hasActiveClass: PropTypes.bool,
}

MenuItem.defaultProps = {
  isExternal: false,
  hasActiveClass: true,
}

export default MenuItem
