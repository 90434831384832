import React from "react"
import PropTypes from "prop-types"
import Header from "components/header"
import PreFooter from "components/pre-footer"
import Footer from "components/footer"
import "styles/global.scss";

const Layout = ({ children }) => (
  <>
    <Header />
    <main>
      {children}
    </main>
    <PreFooter />
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
