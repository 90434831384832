import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import style from "./index.module.scss"

const Svg = ({ src, alt, className }) => {

  const svgClasses = classNames(
    className,
    [style.svg]
  );

  return (
    <img
    className={svgClasses}
    src={src}
    alt={alt}
    />
  )
}


Svg.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
}

Svg.defaultProps = {
  alt: "",
  className: "",
}

export default Svg
