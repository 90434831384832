import React from "react"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"
import classNames from "classnames"
import style from "./index.module.scss"

const Section = ({ as, children, hasMargin, hasPadding, hasBackground, className, containerClass}) => {

  const sectionClasses = classNames (
    {[style.margin]: hasMargin},
    {[style.padding]: hasPadding},
    {[style.background]: hasBackground},
    className
  )

  return (
    <section className={sectionClasses}>
      <Container maxWidth="md" className={containerClass}>
        {children}
      </Container>
    </section>
  )
}

Section.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  hasMargin: PropTypes.bool,
  hasPadding: PropTypes.bool,
  hasBackground: PropTypes.bool,
  className: PropTypes.string,
  containerClass: PropTypes.string,
}

Section.defaultProps = {
  as: "section",
  hasMargin: true,
  hasPadding: false,
  hasBackground: false,
  className: "",
  containerClass: "",
}

export default Section
