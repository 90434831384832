import React, { useState, useEffect } from "react"
import Container from "@material-ui/core/Container"
import { Link } from "gatsby"
import Svg from "components/svg"
import MenuItem from "components/menu-item"
import classNames from "classnames"
import IconButton from "@material-ui/core/IconButton"
import PhoneIcon from "@material-ui/icons/Phone"
import MailIcon from "@material-ui/icons/MailOutlined"
import style from "./index.module.scss"
import logo from "images/logo.svg"
import { motion, useViewportScroll } from "framer-motion"

const Header = () => {

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMenuOnTop, setMenuOnTop] = useState(true);
  const { scrollYProgress } = useViewportScroll();

  const variants = {
    onTop: { boxShadow: "none" },
    notOnTop: { boxShadow: "0 0 32px 0 rgba(200, 200, 200, 0.2)" },
  }

  const toggleMenu = () => {
    if (isMenuOpen) {
      setMenuOpen(false);
    } else {
      setMenuOpen(true);
    }
  }

  const navClasses = classNames (
    [style.nav],
    {[style.navMobileOpen]: isMenuOpen},
    {[style.navMobileClosed]: !isMenuOpen}
  )

  const toggleClasses = classNames (
    {[style.toggleOpen]: isMenuOpen},
    {[style.toggleClosed]: !isMenuOpen}
  )

  useEffect( () => scrollYProgress.onChange(latest => {
    if (latest === 0) {
      setMenuOnTop(true);
    } else {
      setMenuOnTop(false);
    }
  }), [scrollYProgress])

  return (
    <motion.header
      className={style.header}
      animate={isMenuOnTop ? "onTop" : "notOnTop"}
      variants={variants}
    >
      <Container className={style.container} maxWidth="md">
        <div className={style.brand}>
          <Link to="/">
            <Svg
              src={logo}
              alt="Hermine Schett"
            />
          </Link>
        </div>
        <IconButton className={toggleClasses} aria-label="toggle menu" size="small" onClick={toggleMenu}>
          <span className={style.hamburgerBarOne}></span>
          <span className={style.hamburgerBarTwo}></span>
          <span className={style.hamburgerBarThree}></span>
        </IconButton>
        <nav className={navClasses}>
          <ul className={style.menu}>
            <MenuItem to="/ueber-mich/" text="Über mich" />
            <MenuItem to="/rahmenbedingungen/" text="Rahmenbedingungen" />
            <MenuItem to="/kontakt/" text="Kontakt" />
          </ul>
          <div className={style.contact}>
            <a aria-label="phone" href="tel:004367762760453" className={style.contactButton}>
              <PhoneIcon titleAccess="phone icon" className={style.icon}/>
            </a>
            <a aria-label="mail" href="mailto:praxis@psychotherapie-schett.at" className={style.contactButton}>
              <MailIcon titleAccess="mail icon" className={style.icon}/>
            </a>
          </div>
        </nav>
      </Container>
    </motion.header>
  )
}

export default Header
